<template>
  <div class="MBcont-course" :class='$mq'>
    <h2>Мини-курсы</h2>

    <splide class="MBmini-courses" :class='$mq' :slides="miniCourses" :options="options">
      <splide-slide v-for="(slide, index) in miniCourses" :key="slide.to">
        <router-link :to="slide.to" class="MBcourse mini" :class='$mq'>
          <KImage :src="slide.image" imgClass='MBcourse-cover' alt=""/>
          <h2 class="mini tiny">{{ slide.title }}</h2>
          <h3 class="mini">{{ slide.subTitle }}</h3>
          <KImage :src="require('@/assets/membersh/course-in.svg')" imgClass='MBcourse-arrow mini' alt=""/>
        </router-link>
      </splide-slide>
    </splide>

    <h1>Мои курсы</h1>
    <p class="subtitle">Если ваш курс не появился, пожалуйста, обновите страницу :)</p>
    <div v-if="loadingState == 'loading'">
      <p>Загрузка ...</p>
    </div>
    <div v-if="loadingState == 'success'" class="MBcourses-flex">
      <div v-if="courses.length == 0">
        <p>К сожалению, здесь пока ничего нет :( <br> Хотите изучить что-нить новенькое? <a href="/newyear">Вам сюда</a>
        </p>
      </div>

      <!-- COURSE -->
      <div class="MBcourse" :class='$mq' v-for="(course) in getCourses"
                   :key="course.id">
        
        <div  @click="getRoute(course.id)" class="MBcourse-in">

          <!-- LOCK -->
          <div v-if="isLocked(course.id)" class="MBcourse-lock" :class='$mq'>
            <div class="text">
              <div class="icon"></div>
              <p>Курс больше недоступен&#160;:(</p>
            </div>
            <button @click="showPopup1(course.id)">Продлить</button>
          </div>

          <KImage :src="getImage(course.id)" imgClass='MBcourse-cover' alt=""/>
          <h2><span class="MBcourse-h" v-html="course.name"></span></h2>
          <KImage :src="require('@/assets/membersh/course-in.svg')" imgClass='MBcourse-arrow' alt=""/>
        </div>

        <!-- TIME -->
        <div class="MBtime" :class='$mq'>
          {{ getLeftMonths(course.id) }}
          <button @click="showPopup1(course.id)">Продлить</button>
        </div>
        <!-- <div class="MBnewicon"></div> -->
      </div>

    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import {createOrder, loadCourses} from "@/service/api";
import {useRouter} from 'vue-router'
import KImage from "../Widgets/KImage";
import imageMini1 from '@/assets/membersh/mini-3steps.jpg'
import imageMini2 from '@/assets/membersh/mini-bodoir.jpg'
import imageMini3 from '@/assets/membersh/mini-bjd.jpg'
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';



export default {
  components: {
    KImage,
    Splide,
    SplideSlide
  },
  data() {
    return {
      courseId: null,
      miniCourses: [
        {
          to: "/mycourses/facemistakes/1",
          image: imageMini1,
          title: "3 шага",
          subTitle: "к красивой кукле",
        },
        {
          to: "/mycourses/bodoir-materials",
          image: imageMini2,
          title: "Материалы",
          subTitle: "для будуарной куклы",
        },
        {
          to: "/mycourses/bjd-materials",
          image: imageMini3,
          title: "Материалы",
          subTitle: "для шарнирной куклы",
        }
      ],
      options: {
        type: 'slide',//'loop'
        rewind: true,
        perPage: 3,
        arrows: this.$mq === 'mobile' || this.$mq === 'halftablet',
        gap: '0.1rem',
        pagination: false,
        cover: true,
        start: 1,
        breakpoints: {
          700: {
            perPage: 2,
          },
          1000: {
            perPage: 3,
          },
        },
      },
    };
  },
  setup() {
    const router = useRouter()
    const loadingState = ref('loading')
    const courses = ref([])
    const courseEntities = ref([])

    const fetchCourses = () => {
      loadingState.value = 'loading'
      return loadCourses()
        .then(response => {

          var items = [];
          response.data.data.forEach(item => {
            let entity = {};
            if (item.finished_at) {
              let date = new Date(item.finished_at)
              let now = new Date()
              entity.monthsLeft = date.getMonth() - now.getMonth() + 12 * (date.getFullYear() - now.getFullYear())

              let diffTime = date - now
              diffTime = diffTime < 0 ? 0 : diffTime
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

              entity.daysLeft = diffDays
            }
            entity.id = item['course_id']
            entity.locked = item['locked']
            items.push(entity)
          })
          loadingState.value = 'success'
          courses.value = items
        })
        .catch(error => {
          //todo add error view
          if (error.response.status === 404) {
            router.push("/404")
          }
          loadingState.value = 'error'
        })
    }
    return {loadingState, fetchCourses, courses, courseEntities}
  },
  created() {
    this.fetchCourses()
  },
  methods: {
    isLocked(courseId) {
      let entity = this.courses.filter(item => {
        return item.id === courseId || item.id === courseId + ""
      })[0]
      return entity.locked == 1
    },
    getImage(id) {
      if (id == 1) {
        return require('@/assets/membersh/Course-head.jpg')
      }
      if (id == 2) {
        return require('@/assets/membersh/Course-body.jpg')
      }
      if (id == 3) {
        return require('@/assets/membersh/Course-drawing.jpg')
      }
      if (id == 4) {
        return require('@/assets/membersh/Course-costume.jpg')
      }
      if (id == 5) {
        return require('@/assets/membersh/course-bjd.jpg')
      }
      if (id == 6) {
        return require('@/assets/membersh/beginners-book.jpg')
      }
      if (id == 7) {
        return require('@/assets/membersh/course-childhead.jpg')
      }
      if (id == 8) {
        return require('@/assets/membersh/beginners2.jpg')
      }
      if (id == 9) {
        return require('@/assets/membersh/Course-emotions.jpg')
      }
      return require('@/assets/membersh/Course-head.jpg')
    },
    getRoute(id) {
      if (this.isLocked(id)) {
        return
      }
      if (id == 1) {
        this.$router.push('/mycourses/head/1')
      }
      if (id == 2) {
        this.$router.push('/mycourses/body/1')
      }
      if (id == 3) {
        this.$router.push('/mycourses/draw/1')
      }
      if (id == 4) {
        this.$router.push('/mycourses/costume/1')
      }
      if (id == 5) {
        this.$router.push('/mycourses/bjd/1')
      }
      if (id == 6) {
        this.$router.push('/mycourses/beginner/1')
      }
      if (id == 7) {
        this.$router.push('/mycourses/childhead/1')
      }
      if (id == 8) {
        this.$router.push('/mycourses/beginner2/1')
      }
      if (id == 9) {
        this.$router.push('/mycourses/emotions/1')
      }
    },
    getLeftMonths(courseId) {
      let entity = this.courses.filter(item => {
        return item.id === courseId || item.id === courseId + ""
      })[0]
      if (entity.monthsLeft && entity.monthsLeft>0) {
        return "Доступен " + entity.monthsLeft +" "+ this.declOfNum(entity.monthsLeft,["месяц", "месяца", "месяцев"])
      }
      if (entity.daysLeft) {
        return "Доступен " + entity.daysLeft +" "+ this.declOfNum(entity.daysLeft,["день", "дня", "дней"])
      }
      if (entity.daysLeft === 0) {
        return "Курс не доступен"
      }
      return "Доступ неограничен"
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100; var n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    },
    showPopup1(courseId) {
      this.courseId = courseId
      window.tryToPay = this.tryToPay
      this.$swal.fire({
        customClass: 'popup-long',
        background: '#ffffff',
        // html: '<h2>Продлить курс</h2><h3>"Анатомия тела"</h3><table><tr><td><b>На 3 месяца</b></td><td>990&#8381;</td><td><button class="buy">Продлить</button></td></tr><tr><td><b>На 6 месяцев</b></td><td>1490&#8381;</td><td><button class="buy">Продлить</button></td></tr><tr><td><b>На 9 месяцев</b></td><td>2490&#8381;</td><td><button class="buy">Продлить</button></td></tr></table>',
        html: '<h2>Продлить курс</h2><h3>"Анатомия тела"</h3><div class="flex-table row" :class="$mq"><div class="flex-row head">На 3 месяца</div><div class="flex-row price">990&#8381;</div>' +
          '<div onclick="tryToPay(\'course_prolongation_3\')" class="flex-row button">Продлить</div></div><div class="flex-table row"><div class="flex-row head">На 6 месяцев</div><div class="flex-row price">1490&#8381;</div>' +
          '<div onclick="tryToPay(\'course_prolongation_6\')" class="flex-row button">Продлить</div></div><div class="flex-table row"><div class="flex-row head">На 12 месяцев</div><div class="flex-row price">2490&#8381;</div>' +
          '<div onclick="tryToPay(\'course_prolongation_12\')" class="flex-row button">Продлить</div></div>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
      })
    },
    tryToPay(type) {
      this.$swal.close()
      createOrder(this.courseId, type)
        .then(response => {
          let orderId = response.data.data.order_id
          this.$router.push('/order/'+orderId)
        })
    }
  },
  computed: {

    getCourses() {
      return this.$store.state.courses.filter(item => {
        let ids = this.courses
          .map(item => {
            return item.id
          })
        return ids.indexOf(item.id + "") >= 0 || ids.indexOf(item.id) >= 0
      })
    },
  },
}


</script>

<style>
.popup-long {
  padding: 2em 3em !important;
  font-family: "Montserrat", sans-serif;
  text-align: center!important;
  color:rgb(72, 36, 49)!important;
}

  .popup-long h2 {
      font-family: "Montserrat", sans-serif;
      text-align: center;
      font-size: 1.8em;
      color:rgb(72, 36, 49)!important;
    }
  .popup-long h3 {
      font-family: "Montserrat", sans-serif;
      text-align: center;
      font-size: 1.2em;
      padding-bottom: 0.5em;
      color:rgb(72, 36, 49)!important;
  }

  .flex-table {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    color:rgb(72, 36, 49)!important;
    box-sizing: border-box;
  }
    .flex-row {
      width: 33%;
      text-align: left;
      margin: 1em 0;
      box-sizing: border-box;
    }    
      .flex-row.head {
        font-weight: 600;
      }
      .flex-row.price {
        text-align: center;
      }
      .flex-row.button {
        cursor: pointer;
        text-align: center;
        background-color: #cc6157;
        box-shadow: 0 0.15em 0.5em #cc615799;
        padding: 0.5em 1em;
        border-radius: 3em;;
        color: white;
        font-family: "Montserrat", sans-serif;
        font-size: 0.9em;
        font-weight: 600;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
      }
        .flex-row.button:hover {
          transform: scale(1.1);
        }
        @media screen and (max-width: 480px) {
          .flex-row {
            margin:0.25em 0;
          }
          .flex-row.head {
            width: 67%;
          }
          .flex-row.button {
            width: 100%;
            margin-bottom: 2em;
          }
          .flex-row.price {
            text-align: right;
          }
          .popup-long h3 {
            padding-bottom: 1.5em;
          }
        }
  .popup-long table {
    color:rgb(72, 36, 49)!important;
    margin: 0 auto;
    width: 100%;
  }
  .popup-long table td {
    padding: 1em 0;
  }
  .popup-long button.buy {
      background-color: #cc6157;
      border: none;
      padding: 0.5em 2em;
      border-radius: 3em;;
      color: white;
      font-family: "Montserrat", sans-serif;
      font-size: 0.9em;
      font-weight: 600;
      transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
      /* box-shadow: 0 0 1em #cc6157; */
  }
    .popup-long button.buy:hover{
      transform: scale(1.1);
    }

.MBcourse-in {
  position: relative;
  z-index: 10;
}

.MBcourse-lock {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(72, 36, 49, .8);
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 3em;
  box-sizing: border-box;
  line-height: 1em;
  color: #ffb1a4;
}
  .MBcourse-lock.halftablet {
    padding: 1em;
  }
  .MBcourse-lock:hover {
    color: #ffb1a4;
  }
  .MBcourse-lock button {
    width: 100%;    
    border: none;
    background: #ffb1a4;
    border-radius: 10em;
    padding: 0.7em 1em;
    font-weight: 600;
    margin:0;
  }
  .MBcourse-lock .text {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5em;
    font-size: 1.2em;
  }
    .MBcourse-lock.laptop .text,
    .MBcourse-lock.halftablet .text {
      font-size: 1em;
    }
    .MBcourse-lock .icon {
      width: 42px;
      height: 60px;
      background-image: url('~@/assets/membersh/lock-course.svg');
      background-size: 42px 60px;
      flex-shrink: 0;
      margin-right: 1em;
    }
      .MBcourse-lock.laptop .icon,
      .MBcourse-lock.halftablet .icon {
        margin-right: 0.5em
      }
    .MBcourse-lock p {
      width: min-content;
    }



.MBtime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, .07);
  color: #333;
  font-size: 0.8em;
  line-height: 1em;
  padding: 0.7em 1.5em 0.7em 3.5em;
  background-image: url('~@/assets/membersh/mycourses.svg');
  background-repeat: no-repeat;
  background-size: 2.3em;
  background-position: 0.8em -14.7em;
  border-radius: 0 0 0.7em 0.7em;
}
  .MBtime.halftablet {
    font-size: 0.7em;
    padding-left: 2.5em; 
    background-position: 0.8em 1em;
  }
  .MBtime.mobile {
    font-size: 0.7em;
  }
  .MBtime button {
    border: none;
    background: #ffb1a4aa;
    border-radius: 10em;
    padding: 0.3em 1em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 0.9em;
  }
.MBcont-course {
  max-width: 1100px;
  margin: 0 auto;
  /* border-top: 1px solid #cbcbcb; */
  padding: 1em;
  min-height: 300px;
  position: relative;
  box-sizing: border-box;
}

.MBnewicon {
  position: absolute;
  width: 80px;
  z-index: 11;
  height: 70px;
  background: url('~@/assets/membersh/new.png') no-repeat;
  background-size: 80px;
  top: -1em;
  right: -1.3em;
}

.MBcont-course h1 {
  font-size: 2.5em;
  line-height: 1.2;
  text-align: left;
  margin: 0 0 0.5em 0;
  font-weight: normal;
}

.MBcont-course h2 {
  font-size: 1.5em;
}

.MBcont-course .subtitle {
  position: relative;
  top: -25px;
  font-size: 10pt;
}

.MBcourses-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.MBcourse.mini {
  max-width: 200px;
  width: 18%;
  min-width: 180px;
}

.MBcourse {
  position: relative;
  width: 31%;
  color: white;
  display: block;
  margin-bottom: 30px;
  margin-right: 1em;
  transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
}

.MBcourse.laptop {
  flex-grow: 1;
}

.MBcourse.tablet, .MBcourse.halftablet {
  flex-grow: 1;
  width: 45%;
  margin-right: 1em;
}

.MBcourse.mobile {
  flex-grow: 1;
  margin-right: 0;
  width: 100%;
}

.MBcourse:hover {
  transform: scale(1.05);
}

.MBcourse .MBcourse-cover {
  width: 100%;
}

.MBcourse h2 {
  position: absolute;
  bottom: 2em;
  left: 30px;
  text-align: left;
  margin: 0;
  font-size: 1em;
}

.MBcourse.laptop h2,
.MBcourse.tablet h2 {
  font-size: 0.9em;
}

.MBcourse.halftablet h2,
.MBcourse.mobile h2 {
  left: 1em;
  font-size: 0.8em;
  /* bottom: 2.5em; */
}

.MBcourse.laptop h2 span.MBcourse-h span,
.MBcourse.tablet h2 span.MBcourse-h span {
  top: 20px;
}

.MBcourse.halftablet h2 span.MBcourse-h span,
.MBcourse.mobile h2 span.MBcourse-h span {
  top: 14px;
}

.MBcourse.halftablet h2 span.MBcourse-h span,
.MBcourse.mobile h2 span.MBcourse-h span {
  font-size: 1.5em;
}

.MBcourse h3 {
  position: absolute;
  bottom: 25px;
  left: 15px;
  text-align: left;
  margin: 0;
}

.MBcourse h2 span.MBcourse-h {
  color: white;
  font: 2em/1.2 'Kudryashev', arial, sans-serif;
  text-transform: uppercase;
  top: 0;
  right: 0;
  margin: 0;
}

.MBcourse h2 span.MBcourse-h span {
  font: 40pt/40pt 'TheArtist', arial, sans-serif;
  text-transform: none;
  position: absolute;
  top: 25px;
  right: -15px;
  color: white;
}

.MBcourse-arrow {
  width: 18px;
  position: absolute;
  bottom: 4.5em;
  right: 20px;
}

.MBcourse-arrow.mini {
  width: 0.7em;
  bottom: 1.2em;
  right: 0.5em;
}

.MBcourse h2.mini {
  font-size: 2.5em;
  left: 15px;
  bottom: 15px;
  line-height: 1.5em;
}

.MBcourse h2.mini.tiny {
  font-size: 1.5em;
  bottom: 20px;
}

h3.mini {
  font-size: 0.8em;
  bottom: 10px;
}

.mini:hover {
  color: white;
}

.MBcourse.mini {
  max-width: 180px;
  min-width: 180px;
}

.MBcourse.mini.mobile {
  width: 40% !important;
  min-width: auto;
  font-size: 0.7em;
}

.MBmini-courses {
  display: flex;
  flex-wrap: wrap;
}

.MBmini-courses.mobile {
  justify-content: space-between;
}


</style>